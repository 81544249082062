<template>
    <div class="zong" v-if="title !== '项目' && title !== '专业' ">
        <el-menu :default-active="$route.path" router class="el-menu-vertical-demo" @select="open"
            v-for="(item, index) in menu" :key="index">
            <el-submenu index="1" :style="{ backgroundColor: dangqian == index ? '#ecf5ff' : '#fff' }">
                <template slot="title">
                    <span slot="title">{{ item.title }}</span>
                </template>
                <el-menu-item-group>
                    <el-menu-item v-for="(items, indexs) in item.list" :key="indexs" :index="items.value">{{ items.name
                        }}</el-menu-item>
                </el-menu-item-group>
            </el-submenu>
        </el-menu>
    </div>
    <div class="zong" style="flex: 1;" v-else>
        <el-menu background-color="transparent" :default-active="activeindex" class="el-menu-vertical-demo no-dropdown-icon" v-for="(item, index) in menu"
            :key="index" @open="((index,indexPath) => {fatheropen(index,indexPath,item.id)})" @select="open" :default-openeds="activeopen">
            <el-submenu :index="index.toString()">
                <template slot="title">
                    <el-tooltip slot="title" class="item" effect="dark" :content="item.title" placement="right">
                        <span class="title">{{ item.title }}</span>
                    </el-tooltip>

                </template>
                <el-menu-item-group>
                    <el-menu-item v-for="(items, indexs) in item.list" :key="indexs" :index="index + '-' + indexs"
                        :style="{ color: activeindex == index + '-' + indexs ? '#3E44B5' : '#0F1A4E',backgroundColor: activeindex == index + '-' + indexs ? '#E4DFFF !important' : 'transparent !important',borderRight:activeindex == index + '-' + indexs?'4px solid #3E44B5':'none'}"
                        @click="yemianxian(items.value, index, indexs)">
                        <img style="width: 20px;height: 20px;margin-right: .125rem;" v-if="items.imgPath" :src="activeindex == index + '-' + indexs?items.seleImgPath:items.imgPath" alt="">
                        {{ items.name}}
                    </el-menu-item>
                </el-menu-item-group>
            </el-submenu>
        </el-menu>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String
        },
        menu: {
            type: Array
        },
        keypath: {
            type: String
        }
    },
    data: function () {
        return {
            isCollapse: true,
            activeindex: '0-0',
            activeopen: [],
            dangqian: 0
        }
    },
    watch: {
        keypath: {
            handler(newData, oldData) {
                console.log('activeopen',newData,oldData)
                if(newData){
                    this.activeindex = newData
                }else{
                    this.activeindex = '0-0'
                }
                this.activeopen = []
                // var arr1 = newData.split('-')
                var arr1 = this.activeindex.split('-')
                this.activeopen.push(arr1[0])
            }
        }
    },
    mounted() {
        if(localStorage.getItem('tanIndex')){
            this.activeindex = localStorage.getItem('tanIndex')
        }else{
            this.activeindex = this.keypath
        }
        // this.activeindex = this.keypath
        // console.log(this.keypath)
    },
    methods: {
        open(key, keyPath) {
            // console.log(key, keyPath);
            this.dangqian = key
            // if (this.title == '项目') {
            //     this.$router.push('/home')
            // }
        },
        fatheropen(key, keyPath,id) {
            console.log(key, keyPath,id);
            this.dangqian = key
            
            // if (this.title == '项目') {
            //     this.$router.push('/home?id='+id)
            // }
        },
        yemianxian(e, index, indexs) {
            this.$router.push(e)
            this.dangqian = index
            // localStorage.removeItem('tabIndex')
            this.activeindex = index + '-' + indexs
            this.$emit('tabIndex',this.activeindex)
            console.log(this.activeindex)
        }
    }
}
</script>


<style>
.zong {
    overflow-y: scroll;
}
.zong::-webkit-scrollbar { width: 0 !important }
.el-menu-vertical-demo:not(.el-menu--collapse) {
    /* width: 2.5rem; */
    /* height: 100%; */
}
.no-dropdown-icon .el-submenu__title {
  padding-right: 0 !important; /* 移除右侧填充，默认情况下会有下拉图标 */
}
.title {
    width: 1.925rem;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* color: #000727; */
    font-weight: 500;
    font-size: .2rem;
    /* 　　overflow:hidden;
　　text-overflow:ellipsis;
　　white-space:nowrap; */
}
.el-submenu__title:hover{
      background-color: transparent !important;
    }
    .el-menu-item:focus, .el-menu-item:hover{
        background: #E4DFFF !important;
      color: #3E44B5 !important;
    }
    .el-menu-item.is-active{
        background-color: #E4DFFF !important;
        color: #3E44B5 !important;
    }
    .el-submenu__title i{
        color: #000727 !important;
    }

::v-deep.el-menu-item.is-active{
    background-color: #fff;
}

</style>