import request from '@/utils/request'
// 新增项目
export function addProject(data) {
    return request({
        url: '/manage/manage/addProject',
        method: 'post',
        data: data
    })
}
// 项目列表
export function listProject(data) {
    return request({
        url: '/manage/manage/listProject',
        method: 'post',
        data: data
    })
}
// 所有项目列表
export function listProjectAll(data) {
    return request({
        url: '/manage/manage/listProjectAll',
        method: 'post',
        data: data
    })
}
// 修改项目
export function updateProject(data) {
    return request({
        url: '/manage/manage/updateProject',
        method: 'post',
        data: data
    })
}
// 删除项目
export function deleteProject(data) {
    return request({
        url: '/manage/manage/deleteProject',
        method: 'post',
        data: data
    })
}
// 新增机构
export function addOrganization(data) {
    return request({
        url: '/manage/manage/addOrganization',
        method: 'post',
        data: data
    })
}
//机构列表
export function listOrganization(data) {
    return request({
        url: '/manage/manage/listOrganization',
        method: 'post',
        data: data
    })
}
// 修改机构
export function updateOrganization(data) {
    return request({
        url: '/manage/manage/updateOrganization',
        method: 'post',
        data: data
    })
}
// 删除机构
export function deleteOrganization(data) {
    return request({
        url: '/manage/manage/deleteOrganization',
        method: 'post',
        data: data
    })
}
// 新增部门
export function addDepartment(data) {
    return request({
        url: '/manage/manage/addDepartment',
        method: 'post',
        data: data
    })
}
//部门列表
export function listDepartment(data) {
    return request({
        url: '/manage/manage/listDepartment',
        method: 'post',
        data: data
    })
}
// 修改部门
export function updateDepartment(data) {
    return request({
        url: '/manage/manage/updateDepartment',
        method: 'post',
        data: data
    })
}
// 删除部门
export function deleteDepartment(data) {
    return request({
        url: '/manage/manage/deleteDepartment',
        method: 'post',
        data: data
    })
}
// 新增地区
export function addRegion(data) {
    return request({
        url: '/manage/manage/addRegion',
        method: 'post',
        data: data
    })
}
//地区列表
export function listRegion(data) {
    return request({
        url: '/manage/manage/listRegion',
        method: 'post',
        data: data
    })
}
// 修改地区
export function updateRegion(data) {
    return request({
        url: '/manage/manage/updateRegion',
        method: 'post',
        data: data
    })
}
// 删除地区
export function deleteRegion(data) {
    return request({
        url: '/manage/manage/deleteRegion',
        method: 'post',
        data: data
    })
}
// 新增角色
export function addUserRole(data) {
    return request({
        url: '/manage/manage/addUserRole',
        method: 'post',
        data: data
    })
}
//角色列表
export function listUserRole(data) {
    return request({
        url: '/manage/manage/listUserRole',
        method: 'post',
        data: data
    })
}
// 修改角色
export function updateUserRole(data) {
    return request({
        url: '/manage/manage/updateUserRole',
        method: 'post',
        data: data
    })
}
// 删除角色
export function deleteUserRole(data) {
    return request({
        url: '/manage/manage/deleteUserRole',
        method: 'post',
        data: data
    })
}
// 新增用户
export function addUser(data) {
    return request({
        url: '/manage/manage/addUser',
        method: 'post',
        data: data
    })
}
//用户列表
export function listUser(data) {
    return request({
        url: '/manage/manage/listUser',
        method: 'post',
        data: data
    })
}
//全部用户列表
export function listUserAll(data) {
    return request({
        url: '/manage/manage/listUserAll',
        method: 'post',
        data: data
    })
}
//各项目用户列表
export function listNotProjectUserAll(data) {
    return request({
        url: '/manage/manage/listNotProjectUserAll',
        method: 'post',
        data: data
    })
}
// 修改用户
export function updateUser(data) {
    return request({
        url: '/manage/manage/updateUser',
        method: 'post',
        data: data
    })
}
// 删除用户
export function deleteUser(data) {
    return request({
        url: '/manage/manage/deleteUser',
        method: 'post',
        data: data
    })
}
// 新增岗位
export function addPersonnelPost(data) {
    return request({
        url: '/manage/manage/addPersonnelPost',
        method: 'post',
        data: data
    })
}
//岗位列表
export function listPersonnelPost(data) {
    return request({
        url: '/manage/manage/listPersonnelPost',
        method: 'post',
        data: data
    })
}
// 修改岗位
export function updatePersonnelPost(data) {
    return request({
        url: '/manage/manage/updatePersonnelPost',
        method: 'post',
        data: data
    })
}
// 删除岗位
export function deletePersonnelPost(data) {
    return request({
        url: '/manage/manage/deletePersonnelPost',
        method: 'post',
        data: data
    })
}
// 提交导入监测点
export function importCheckPoint(data) {
    return request({
        url: '/manage/checkpoint/importCheckPoint',
        method: 'post',
        data: data
    })
}
//用户列表
export function selfData(data) {
    return request({
        url: '/manage/login/selfData',
        method: 'post',
        data: data
    })
}
//登出
export function quit(data) {
    return request({
        url: '/manage/login/quit',
        method: 'post',
        data: data
    })
}
//用户所在项目列表
export function manageUserProject(data) {
    return request({
        url: '/manage/project/manageUserProject',
        method: 'post',
        data: data
    })
}
//部门+机构列表
export function AlllistDepartment(data) {
    return request({
        url: '/manage/department/listDepartment',
        method: 'post',
        data: data
    })
}
//分配项目列表
export function listProjectUser(data) {
    return request({
        url: '/manage/manage/listProjectUser',
        method: 'post',
        data: data
    })
}
//添加分配项目列表
export function addOrdelProjectUser(data) {
    return request({
        url: '/manage/manage/addOrdelProjectUser',
        method: 'post',
        data: data
    })
}
//分配项目列表
export function updateProjectUser(data) {
    return request({
        url: '/manage/manage/updateProjectUser',
        method: 'post',
        data: data
    })
}