<template>

	<el-container class="container">
		<el-header style="height:.75rem;">
			<div class="header">
				<div style="display: flex;align-items: center">
					<div class="imgBox">
						<img src="../../assets/images/newImage/logo.png" alt="" class="logo">
					</div>
					<!-- <div class="menuTab">
					<div class="menuTab_0" v-for="(item, index) in menuTab" :key="index" @click="tab(index,'tiao')">
						<p :style="selectTab == index ? 'font-weight:500;color:#2878FF;' : ''">{{ item.name }}</p>
						<span class="active_block" v-if="selectTab == index"></span>
					</div>
				</div> -->
					<div class="projectAll">
						<!-- <img src="../../assets/images/newImage/all.png" alt=""> -->
						<p>智能测距系统</p>
					</div>
				</div>

				<div>
					<div class="user">
						<img src="../../assets/images/newImage/avatar.png" alt="">
						<p>{{ userinfo.name }}</p>
						<span style="margin: 0 .1875rem;">|</span>
						<span>{{ userinfo.departmentName }}</span>
						<i class="el-icon-switch-button out" @click="out"></i>
					</div>
				</div>

			</div>
		</el-header>
		<el-container class="contents">
			<el-aside style="width: 3.9rem;">
				<!-- <div class="menus"> -->
				<div class="menuTab">
					<div v-for="(item, index) in menuTab" :key="index" @click="tab(index, 'tiao')"
						:class="selectTab == index ? 'menuTab_0 active' : 'menuTab_0'">
						<img :src="selectTab == index ? item.selectimg : item.img" alt="">
						<p>{{ item.name }}</p>
						<!-- <span class="active_block" v-if="selectTab == index"></span> -->
					</div>
				</div>
				<div style="flex: 1;display: flex;flex-direction: column;">
					<p class="menuTitle">{{names}}</p>
					<!-- <div v-if="selectTab == 0"> -->
					<menuList :menu="menulist" title="项目" :keypath="keypath" v-loading="loading" @tabIndex="tabChange"
						v-if="selectTab == 1">
					</menuList>
					<!-- </div> -->
					<!-- <div v-if="selectTab !== 0"> -->
					<menuList :menu="menulist" title="专业" :keypath="keypath" @tabIndex="tabChange"
						v-if="selectTab !== 1">
					</menuList>
				</div>


			</el-aside>
			<el-main>
				<router-view></router-view>
			</el-main>
		</el-container>

	</el-container>
</template>

<script>
// import menuList from '@/components/menuList.vue';
// import Home from '@/components/home.vue';
import menuList from '@/components/menuList.vue';
import { listProjectAll, quit, selfData } from '@/api/system/system'
export default {
	components: {
		menuList
	},
	data: function () {
		return {
			userinfo: {},
			listRequest: [],
			showModal: false,
			selectMenu: 1,
			project: [],
			name: '',
			keypath: '',
			menulist: [],
			loading: false,
			menuTab: [
				{
					name: '首页',
					path: '/indexs',
					img: require('../../assets/images/newImage/train.png'),
					selectimg: require('../../assets/images/newImage/trains.png')

				},
				{
					name: '模型库',
					path: '/stanmanageAll',
					img: require('../../assets/images/newImage/muxing.png'),
					selectimg: require('../../assets/images/newImage/muxings.png')
				},
				{
					name: '测距管理',
					path: '/stationArea',
					img: require('../../assets/images/newImage/test.png'),
					selectimg: require('../../assets/images/newImage/tests.png')
				},

				{
					name: '系统设置',
					path: '/project',
					img: require('../../assets/images/newImage/system.png'),
					selectimg: require('../../assets/images/newImage/systems.png')
				}
			],
			selectTab: 1,
			names:''
		}
	},
	mounted() {
		window.addEventListener("resize", () => {
			// this.screenWidth = document.body.clientWidth;
			// this.screenHeight = document.body.clientHeight;
			// const contentHeight = (document.documentElement.clientHeight / 80 - 1) * 80
			if (document.querySelector('.container')) {
				document.querySelector('.container').style.height = document.documentElement.clientHeight + 'px';

			}
		});
		if (this.$route.path == '/') {
			this.$router.push('/indexs')
		}
		document.querySelector('.container').style.height = document.documentElement.clientHeight + 'px';
		this.getuserInfo()
		// this.menu.forEach((item,index)=>{
		// console.log(item.paths)
		if (this.$route.path.includes('/manageUser') || this.$route.path.includes('/project') || this.$route.path.includes('/user')) {
			this.selectTab = 3

		}
		if (this.$route.path.includes('/stanmanageAll')) {
			this.selectTab = 1
		}
		if (this.$route.path.includes('/sectionManagement') || this.$route.path.includes('/stationArea') || this.$route.path.includes('/stanmanageproject') || this.$route.path.includes('/importDetection') || this.$route.path.includes('/projectUser')) {
			this.selectTab = 2
		}
		this.tab(this.selectTab, 'butiao')


		// })
	},
	methods: {
		tab(index, a) {
			this.selectTab = index
			if (a == 'tiao') {
				this.keypath = '0-0'
			}
			this.menuTab.forEach((item,indexs)=>{
				if(index == indexs){
					this.names = item.name
				}
			})
			console.log(this.selectTab, 'qqqqqqqqqq')
			if (this.selectTab == 2) {
				this.getlistProject('go')
				let that = this
				if (a == 'tiao') {
					setTimeout(() => {
						that.$router.push({ path: that.menuTab[that.selectTab].path + '?id=' + that.project[0].id })

					}, 500);
				}

			} else if (this.selectTab == 0) {
				if (a == 'tiao') {
					this.$router.push({ path: this.menuTab[index].path })

				}
			} else if (this.selectTab == 1) {
				this.menulist = [
					{
						title: '模型库',
						list: [
							{
								value: '/stanmanageAll',
								name: '模型管理'
							}
						]
					}
				]
				if (a == 'tiao') {
					this.$router.push({ path: this.menuTab[index].path })

				}
			} else {
				this.menulist = [
					{
						title: '系统设置',
						list: [
							{
								value: '/project',
								name: '项目列表'
							},
							{
								value: '/manageUser',
								name: '超管管理'
							},
							{
								value: '/user',
								name: '人员列表'
							}
						]
					}
				]
				if (a == 'tiao') {
					this.$router.push({ path: this.menuTab[index].path })

				}
			}
		},
		tabChange(e) {
			console.log(e)
			this.keypath = e
		},
		go(e, index) {
			this.selectMenu = index
			if (index == 2) {
				listProjectAll({ userId: sessionStorage.getItem('userid') }).then(res => {
					console.log(res)
					if (res.state == 200) {
						this.$router.push('/home?id=' + res.data[0].id)
					}
				})
			} else {
				this.$router.push(e)

			}
		},
		out() {
			this.$confirm('确定注销并退出系统吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				// this.$store.dispatch('LogOut').then(() => {
				//     location.href = '/';
				// })
				quit({}).then(res => {
					console.log(res)
					if (res.state == '200') {
						this.$message.success('退出成功')
						if (sessionStorage.getItem('token')) {
							sessionStorage.removeItem('token')
							sessionStorage.removeItem('userid')
							sessionStorage.removeItem('userName')
							window.location.href = 'http://121.28.102.238:15000/logout'
						}
					} else {
						this.$message.error('退出失败')
					}
				})

			}).catch(() => { });
		},
		async getlistProject(e) {
			await listProjectAll({ name: '', userId: sessionStorage.getItem('userid') }).then(res => {
				if (res.state == 200) {
					this.menulist = []
					this.project = res.data

					res.data.forEach(item => {
						if (item.name) {
							this.menulist.push({
								title: item.name,
								id: item.id,
								list: [
									// {
									// 	name: '站前标段',
									// 	value: '/sectionManagement?id=' + item.id
									// },
									{
										name: '站区管理',
										value: '/stationArea?id=' + item.id,
										imgPath: require('../../assets/images/newImage/zhanqu1.png'),
										seleImgPath: require('../../assets/images/newImage/zhanqu.png')
									},
									// {
									//     name: '项目首页',
									//     value: '/home?id=' + item.id
									// },
									{
										name: '模型管理',
										value: '/stanmanageproject?id=' + item.id,
										imgPath: require('../../assets/images/newImage/tuzhi1.png'),
										seleImgPath: require('../../assets/images/newImage/tuzhi.png')
									},
									{
										name: '待测距任务',
										value: '/importDetection?id=' + item.id,
										imgPath: require('../../assets/images/newImage/luru1.png'),
										seleImgPath: require('../../assets/images/newImage/luru.png')
									},
									{
										name: '项目人员',
										value: '/projectUser?id=' + item.id,
										imgPath: require('../../assets/images/newImage/users1.png'),
										seleImgPath: require('../../assets/images/newImage/users.png')
									}
								]
							})
						}
					});
					this.menulist.forEach((item, index) => {
						item.list.forEach((items, indexs) => {
							var path = this.$route.path + '?id=' + this.$route.query.id
							console.log(path)
							if (items.value == path) {
								console.log(path)
								this.keypath = index + '-' + indexs
							}
						})
					})

				}
			})
		},
		getuserInfo() {
			selfData({}).then(res => {
				if (res.state == 200) {
					this.userinfo = res.data
					sessionStorage.setItem('userid', res.data.id)
					sessionStorage.setItem('userName', res.data.login)
					// this.getUser()
				}
			})
		}
	}
}
</script>
<style>
.el-message-box__btns {
	.el-button:nth-child(1):hover {
		background-color: #E4DFFF !important;
		border-color: #3E44B5 !important;
		color: #3E44B5 !important;
	}

	.el-button:nth-child(2) {
		margin-right: 10px;
		background-color: #3E44B5;
		border-color: #3E44B5;
	}
}
</style>
<style scoped>
.container {
	font-family: sans-serif;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	background: #F0F2F5;
}

.el-header {
	padding: 0;
	background: #02043E;
	padding-right: .375rem;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: .75rem;
	padding: .125rem .2rem;
	/* border-bottom: 1px solid #f5f5f5; */
}

.imgBox {
	/* width: 2.6rem;
	height: .7rem;
	background: #2878FF; */
	/* box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.12); */
	display: flex;
	justify-content: center;
	align-items: center;
	/* width: 0.8rem; */
}

.logo {
	width: .5625rem;
	height: .5125rem;
}

.projectAll {
	display: flex;
	align-items: center;
	/* background: rgba(255, 255, 255, 0.1); */
	margin-left: .3rem;
	font-size: .3rem;
	color: #FEFEFE;
	font-family: "YouSheBiaoTiHei";
}

.projectAll img {
	width: .20rem;
	height: .20rem;
	margin-right: .1rem;
}

.menuTab {
	/* display: flex; */
	/* flex: 1; */
	/* align-items: flex-start; */
	font-weight: 400;
	font-size: .19rem;
	color: rgba(138, 153, 181, 1);
	font-style: normal;
	background: rgba(97, 103, 118, 0.25);
	padding: .25rem .0625rem;
	/* height: .3rem; */
}

.menuTab_0 {
	width: .9rem;
	height: .9rem;
	border-radius: .125rem;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: .4rem;
}

.menuTab_0.active {
	background: #3E44B5;
	color: #fff;
}

.menuTab_0 img {
	width: .375rem;
	height: .375rem;
	margin-bottom: .05rem;
}

.menuTitle {
	font-size: 16px;
	color: #607697;
	margin: .375rem 0 .375rem .375rem;
}

/* .active_block {
	background: #2878FF;
	display: block;
	height: .025rem;
	margin-top: .2rem;
} */

.contents {
	flex: 1;
	overflow: hidden;
}

.flex {
	height: 100%;
	display: flex;
}

.header_right {
	display: flex;
	color: #333;
	font-size: .2rem;
}

.out {
	margin-left: .35rem;
	color: #fff;
	font-size: .2rem;
}

.el-aside {
	display: flex;
	flex-direction: row;
	/* padding: .125rem; */
	overflow: hidden;
	/* background: url(../../assets/images/sideBg.png) no-repeat; */
	/* background-size: 100%; */
	/* background: url(); */
}


.user {
	display: flex;
	/* flex-direction: column; */
	align-items: center;
	/* padding: .2375rem 0 .35rem; */
}

.user img {
	width: .325rem;
	height: .325rem;
	border-radius: 50%;
	/* background: #A6DBFF; */
}

.user p {
	/* font-weight: 600; */
	font-size: .2rem;
	color: #fff;
	margin-left: .125rem;

}

.user span {
	font-weight: 400;
	font-size: .18rem;
	color: #fff;
}

.menus {
	height: 100%;
}

.el-main {
	padding: 0 !important;
	/* margin-left: .25rem; */
	overflow-y: scroll;
	overflow-x: hidden;
	background: #F4F5F7;
}

.menu li {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 1.3375rem;
	/* border-bottom: 1px solid #fff; */
	font-family: PingFangSC, PingFang SC;
	font-weight: 500;
	font-size: 0.2rem;
	color: #FFFFFF;
	cursor: pointer;
}

.menu li:hover {
	background: #002755;
}

.menu li img {
	width: 0.7rem;
	height: 0.7rem;
	margin-bottom: .0375rem;
}

.menu li i {
	font-size: .35rem;
}

.modal {
	position: absolute;
	top: 40px;
	right: 0;
	width: 375px;
	border-radius: 10px;
	padding: 10px 15px;
}

.list {
	padding: 0 10px;
	/* flex: 1; */
	overflow-y: scroll;
	height: 8.75rem;
}

.list-top {
	background: linear-gradient(270deg, #F9FCFF 0%, #D5EAFF 100%);
	border-radius: 8px 8px 0px 0px;
	height: 30px;
	display: flex;
	align-items: center;
	padding: 0 11px;
	font-size: 12px;
	font-family: PingFangSC, PingFang SC;
	font-weight: 400;
	color: #333333;
}

.list_status {
	/* background-image: url(../static/images/blue.png); */
	background-repeat: no-repeat;
	background-size: 100% 100%;
	width: 54px;
	height: 15px;
	text-align: center;
	line-height: 15px;
	font-size: 13px;
	font-family: YouSheBiaoTiHei;
	color: #333;
	margin-right: 5px;
}

::v-deep .el-button--primary:hover {
	background-color: #3E44B5 !important;
	border-color: #3E44B5 !important;
}

::v-deep .el-button--primary:focus {
	background-color: #3E44B5 !important;
	border-color: #3E44B5 !important;
}

::v-deep .el-button--default:hover {
	background-color: #E4DFFF !important;
	border-color: #3E44B5 !important;
	color: #3E44B5 !important;
}

.watch {
	display: flex;
	align-items: center;
	font-size: 12px;
	font-family: PingFangSC, PingFang SC;
	font-weight: 400;
	color: #666666;
}

.list_0 {
	background: #FFFFFF;
	border-radius: 8px;
	margin-bottom: 10px;
}

.content {
	padding: 6px 12px;
	font-family: PingFangSC, PingFang SC;

}

.title {
	font-size: 12px;
	font-weight: 500;
	/* color: #333333; */
	display: flex;
}

.basic {
	width: 105px;
	height: 22px;
	background: #0B82FD;
	border-radius: 4px;
	text-align: center;
	line-height: 21px;
	font-size: 12px;
	font-weight: 400;
	color: #FFFFFF;
	margin: 7px 0;
}

.person {
	display: flex;
	align-items: center;
	margin-left: 15px;
	font-size: 12px;
	font-weight: 400;
	color: #999999;
	margin-bottom: 5px;
}

.person image {
	width: 12px;
	height: 12px;
	margin-right: 5px;
}

.question {
	background: #F7F7F7;
	border-radius: 4px;
	padding: 6px;
	font-size: 12px;
	font-family: PingFangSC, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 21px;
}

::v-deep .el-submenu.is-opened .el-submenu__title {
	background-color: #EEEBFD !important;
	color: #3E44B5 !important;
}

::v-deep .el-select .el-input.is-focus .el-input__inner {
	border-color: #3E44B5 !important;
}

::v-deep .el-input__inner:focus {
	border-color: #3E44B5 !important;
	outline: none;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
	background-color: #3E44B5 !important;
}

::v-deep .el-pagination.is-background .btn-next {
	background: #fff !important;
	border: 1px solid #e8edef;
	color: #666 !important;
}

::v-deep .el-pagination.is-background .btn-prev {
	background: #fff !important;
	border: 1px solid #e8edef;
	color: #666 !important;
}

::v-deep .el-pagination__total {
	color: #333 !important;
}

::v-deep .el-button--text {
	color: #3E44B5;
	font-size: .18rem;
}

::v-deep .el-button--text:hover {
	color: #3E44B5;
	font-size: .18rem;
}

::v-deep .el-button--text:focus {
	color: #3E44B5;
	font-size: .18rem;
}

::v-deep .el-switch__label.is-active {
	color: #3E44B5 !important;
}

::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
	background: #3E44B5 !important;
	border-color: #3E44B5 !important;
}

::v-deep .el-radio__input.is-checked+.el-radio__label {
	color: #3E44B5 !important;
}

::v-deep .el-radio__input.is-checked .el-radio__inner {
	background: #3E44B5 !important;
	border-color: #3E44B5 !important;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
	background: #3E44B5 !important;
	border-color: #3E44B5 !important;
}

::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
	color: #3E44B5 !important;
}

</style>
